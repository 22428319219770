<template>
    <div class="agency-report-home">
        <v-card>
            <v-card-title>Filter Reports</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="selectedVolunteer"
                            :items="volunteers"
                            item-text="text"
                            item-value="value"
                            label="Select Volunteer"
                            outlined
                            :return-object="true"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="selectedNeed"
                            :items="needs"
                            item-text="title"
                            item-value="id"
                            label="Select Need"
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-menu
                            v-model="fromDatePicker"
                            :close-on-content-click="false"
                            :return-value.sync="fromDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="fromDate"
                                    label="From Date"
                                    outlined
                                    dense
                                    autocomplete="off"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="fromDate"
                                no-title
                                scrollable
                                :max="new Date().toISOString().substr(0, 10)"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="fromDatePicker = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="fromDatePicker = false">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-menu
                            v-model="toDatePicker"
                            :close-on-content-click="false"
                            :return-value.sync="toDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="toDate"
                                    label="To Date"
                                    outlined
                                    dense
                                    autocomplete="off"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="toDate"
                                no-title
                                scrollable
                                :max="new Date().toISOString().substr(0, 10)"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="toDatePicker = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="toDatePicker = false">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-btn color="primary" class="mt-4" @click="filterReports">Search</v-btn>
                <v-btn color="secondary" class="mt-4 ml-2" @click="clearFilters">Clear</v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-title>Report Details</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Needs Report</v-toolbar-title>
                            <v-btn icon @click="downloadExcel">
                                <v-icon>mdi mdi-microsoft-excel</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "AgencyReportHome",
    data() {
        return {
            fromDate: "",
            toDate: "",
            fromDatePicker: false,
            toDatePicker: false,
            headers: [
                { text: "S.No", value: "sno" },
                { text: "Need Title", value: "needTitle" },
                { text: "No. of Volunteers Worked", value: "volunteersWorked" },
                { text: "Worked Hours", value: "workedHours" },
                { text: "Start Date", value: "startDate" },
                { text: "End Date", value: "endDate" },
            ],
            items: [
                {
                    sno: 1,
                    needTitle: "Food Distribution",
                    volunteersWorked: 10,
                    workedHours: 10,
                    startDate: "2023-01-01",
                    endDate: "2023-01-05",
                },
                {
                    sno: 2,
                    needTitle: "Clothing Drive",
                    volunteersWorked: 15,
                    workedHours: 8,
                    startDate: "2023-02-10",
                    endDate: "2023-02-15",
                },
            ],
            filteredItems: [],
            selectedVolunteer: null,
            selectedNeed: null,
            volunteers: [],
        };
    },
    methods: {
        ...mapActions("need", {
            getVolunteerList: "getVolunteerList",
        }),
        filterReports() {
            const from = new Date(this.fromDate);
            const to = new Date(this.toDate);

            this.filteredItems = this.items.filter((item) => {
                const startDate = new Date(item.startDate);
                const endDate = new Date(item.endDate);
                return (
                    (!this.fromDate || startDate >= from) &&
                    (!this.toDate || endDate <= to)
                );
            });
        },
        downloadExcel() {
            // Implement Excel download logic here
            console.log("Download Excel");
        },
        clearFilters() {
            this.fromDate = "";
            this.toDate = "";
            this.selectedVolunteer = null;
            this.selectedNeed = null;
            this.filteredItems = this.items;
        },
        volunteerList(){
            this.getVolunteerList()
                .then((response) => {
                    this.volunteers = response.fanned.map(volunteer => (
                        {
                        text: volunteer.first_name + " " + volunteer.last_name,
                        value: volunteer._id
                    }));
                })
                .catch((error) => {
                    console.error("Error fetching volunteer list:", error);
                });
        }
    },
      computed: {
    ...mapGetters("need", {
      needs: "getAllNeeds"
    }),
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
    mounted() {
        // Initialize filteredItems with all items
        this.filteredItems = this.items;
        this.volunteerList();
    },
};
</script>

<style scoped>
.agency-report-home {
    padding: 20px;
}
</style>